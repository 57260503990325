<template>
  <b-card>
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <a
            :href="`${themeConfig.apiUrl}/recipes/exports/ingredients.xlsx`"
            class="btn btn-info btn-icon mr-1"
          >
            Exporter
          </a>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <ingredients-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Ajouter un ingrédient</h3>
      <ingredient-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mb-1"
      @change="setPage"
    />
    <table class="table table-hover">
      <template v-for="ingredient in ingredients">
        <tr
          :key="ingredient.id"
          @click="$router.push(`/ingredients/${ingredient.id}`)"
        >
          <td>#{{ ingredient.product_code }}</td>
          <td>
            <h4>
              {{ ingredient.label }}
              <small>
                {{ ingredient.suplier }}
              </small>
              <br />
              <small>
                <b-badge
                  v-if="ingredient.is_organic"
                  pill
                  variant="primary"
                  class="mr-1"
                >
                  Bio
                </b-badge>
                <b-badge
                  v-if="ingredient.is_local"
                  pill
                  variant="info"
                  class="mr-1"
                >
                  Local
                </b-badge>
                <b-badge
                  v-if="ingredient.is_labeled"
                  pill
                  variant="info"
                  class="mr-1"
                >
                  Label
                </b-badge>
              </small>
            </h4>
          </td>
          <td class="text-right">{{ ingredient.price | currency }}</td>
        </tr>
      </template>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BCard, BBadge } from 'bootstrap-vue'
import IngredientForm from '@/components/IngredientForm.vue'
import IngredientsFilters from './IngredientsFilters.vue'

export default {
  components: {
    BCard,
    IngredientForm,
    IngredientsFilters,
    BBadge,
  },
  data() {
    return {
      themeConfig: $themeConfig,
      displayFilters: false,
      displayForm: false,
      ingredients: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 20,
        term: null,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      this.filters = Object.assign(this.filters, this.$route.query)
      const resp = await this.$http.get('/ingredients/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields':
            'results{id,product_code,label,price,suplier,is_organic,is_local,is_labeled},meta',
        },
      })
      this.ingredients = resp.data.results
      this.filters.page = resp.data.meta.page
      this.filters.pages = resp.data.meta.pages
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(ingredient) {
      this.displayForm = false
      this.$router.push(`/ingredients/${ingredient.id}/`)
    },
  },
}
</script>

<style></style>
